<template>
  <div class="dropdown">
    <vs-icon
      icon="more_vert"
      class="dropbtn"
      size="small"
      :style="
        showDropDown ? 'background: #E5E5E5;' : 'background: transparent;'
      "
      @click="showMenu"
    ></vs-icon>
    <div class="dropdown-content" v-if="showDropDown">
      <a
        href="#"
        v-for="(item, index) in data"
        @click="handleClick(item.value)"
        :key="index"
        :class="{ 'dropdown-selected': item.value === currentValue }"
      >
        <img v-if="item.value === 'ME'" :src="userIcon" />
        <img :src="groupIcon" v-else />
        <span class="pl-1">{{ item.text }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    data: {
      type: Array,
    },
    currentValue: {
      type: String,
    },
  },
  components: {},
  data() {
    return {
      userIcon: require("@/assets/images/me.svg"),
      groupIcon: require("@/assets/images/all.svg"),
      showDropDown: false,
    };
  },
  methods: {
    showMenu() {
      this.showDropDown = !this.showDropDown;
    },
    handleClick(val) {
      this.showDropDown = false;
      this.$emit("dropDownClicked", val);
    },
  },
};
</script>

<style lang="scss">
.dropbtn {
  border: none;
  border-radius: 4px;
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown-content {
  display: block;
  position: absolute;
  min-width: 270px;
  overflow: hidden;
  left: -1000%;
  top: 40px;
  z-index: 9999;
  background: #ffffff;
  /* Medium grey */

  border: 0.5px solid #bdbdbd;
  /* Card shadow */

  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  a {
    padding: 8px;
    text-decoration: none;
    display: block;
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 132.3%;
    color: #757575;

    &:hover {
      background: #eaf2ff;
    }
  }
}

span {
  vertical-align: middle;
}

.dropdown-selected {
  background: #eaf2ff;
  span {
    color: #05053f !important;
    @apply font-medium;
  }
}
</style>
