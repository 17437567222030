<template>
  <div class="w-full">
    <vs-card class="transaction mb-0">
      <div slot="header" class="flex justify-between items-center">
        <h3 class="font-normal md:text-lg text-sm">Latest payment activity</h3>
        <custom-drop-down class="merchant-activity-filter" :data="dropDownData" :current-value="dropDownValue" @dropDownClicked="handleDropDown($event)"></custom-drop-down>
      </div>

      <div class="card-body">
        <PaymentRequestWidgets :type="dropDownValue"></PaymentRequestWidgets>

        <div class="w-full flex justify-end py-6">
          <template v-if="switch1 == false">
            <router-link class="seeMore" :to="{ name: 'payment-activity' }">See all</router-link>
          </template>
          <template v-else>
            <router-link class="seeMore" :to="{ name: 'payment-activity', query: { type: 'TX' } }"><u>See all</u></router-link>
          </template>
        </div>
      </div>
    </vs-card>
  </div>
</template>

<script>
import PaymentRequestWidgets from "../widgets/PaymentRequest/PaymentRequestWidgets.vue";
import CustomDropDown from "@/views/components/Dropdown";
import { UserIcon } from "vue-feather-icons";
export default {
  components: {
    CustomDropDown,
    PaymentRequestWidgets,
    UserIcon,
  },
  data() {
    return {
      dropDownValue: "ME",
      transactions: [],
      dropDownData: [
        {
          value: "ALL",
          text: "Show all activity",
        },
        {
          value: "ME",
          text: "Show my activity only",
        },
      ],
      tabsList: [
        { key: "PR", text: "Payment requests" },
        { key: "TX", text: "Transactions" },
      ],
      active: true,
      switch1: false,
      partnerId:
        JSON.parse(localStorage.getItem("user")).userType == "admin"
          ? JSON.parse(localStorage.getItem("user"))._id
          : JSON.parse(localStorage.getItem("user")).partnerId,
    };
  },
  methods: {
    handleDropDown(val) {
      this.dropDownValue = val;
    },
    handleTabs(value) {
      this.switch1 = value === "PR" ? false : true;
    },
  },
};
</script>