<template>
  <vs-table class="transcation-list tableHeader" :data="transactions">
    <template class="tableHeader" slot="thead">
      <vs-th> Request ID</vs-th>
      <vs-th> Reference </vs-th>
      <vs-th> Date sent </vs-th>
      <vs-th> Customer </vs-th>
      <vs-th> Product </vs-th>
      <vs-th> Amount </vs-th>
      <vs-th>Status </vs-th>
      <vs-th> </vs-th>
    </template>

    <template slot-scope="{ data }">
      <vs-tr :key="indextr" v-for="(tr, indextr) in data">
        <vs-td :data="tr.paymentRequestId">{{ tr.paymentRequestId }}</vs-td>
        <vs-td :data="tr.reference">{{ tr.reference }}</vs-td>
        <vs-td :data="tr.createdAt">{{ moment(tr.createdAt) }}</vs-td>
        <vs-td v-if="tr.payeeName !== ''" :data="tr.payeeName">{{ tr.payeeName }}</vs-td>
        <vs-td v-else :data="tr.payeeName">{{ tr.payeePhone !== "" ? tr.payeePhone : tr.payeeEmail }}</vs-td>
        <vs-td :data="tr.requestType">{{ paymentRequestType(tr) }}</vs-td>
        <vs-td :data="tr.amount">{{ formatCurrency(tr.amount.$numberDecimal || tr.amount) }}</vs-td>
        <vs-td :data="tr.paymentStatus"><span :class="changeStatusColor(tr.paymentStatus)">{{ tr.paymentStatus }}</span></vs-td>
        <vs-td :data="tr">
          <router-link
            :to="{
              name: 'staff-payment-requests-detail',
              params: { id: tr._id },
            }"
            class="underline cursor-pointer">View</router-link>
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
export default {
  props: {
    type: {
      type: String,
      default: "ME",
    },
  },
  data() {
    return {
      transactions: [],
      partnerId:
        JSON.parse(localStorage.getItem("user")).userType == "admin"
          ? JSON.parse(localStorage.getItem("user"))._id
          : JSON.parse(localStorage.getItem("user")).partnerId,
    };
  },
  methods: {
    ...mapActions("paymentRequest", ["fetchPaymentRequestListByPartnerId"]),

    moment(date) {
      return moment(date).format("DD-MMM-YYYY");
    },

    changeStatusColor(status) {
      let bgClass = "";
      if (["Expired", "Cancelled"].includes(status)) {
        bgClass = "badge danger";
      } else if (status == "Refunded") {
        // yellow
        bgClass = "badge warning";
      } else if (status == "Completed") {
        bgClass = "badge success";
      } else if (["Pending", "Re-sent"].includes(status)) {
        bgClass = "badge pending";
      } else {
        bgClass = "badge primary";
      }
      return bgClass;
    },

    async getPaymentRequestList(partnerId, page, limit) {
      this.$vs.loading();
      await this.fetchPaymentRequestListByPartnerId(`${partnerId}?page=${page}&limit=${limit}&sort=desc&type=${this.type}`)
        .then((result) => {
          this.transactions = result.data.data.docs;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Notification",
            text: err.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },

    paymentRequestType(val) {
      if (val.requestOptions.includes("pay-later") && val.requestOptions.includes("pay-now")) {
        if (["Pending", "Cancelled", "Expired", "Info-required"].includes(val.paymentStatus)) {
          return "Pay now / Pay later";
        }

        let returnValue = "";
        if (!val.selectedPaymentPlan || val.selectedPaymentPlan === "now") {
          returnValue = "Pay now";
        } else {
          returnValue = val.selectedPaymentPlan.planName ? val.selectedPaymentPlan.planName : val.selectedPaymentPlan.productName;
        }
        return returnValue;
      } else if (val.requestOptions.includes("pay-now")) {
        return "Pay now";
      } else if (val.requestOptions.includes("pay-later")) {

        if (val.payLaterPlan.length !== 1) {
          return "Pay later";
        }

        return ["Active", "Completed", "Submitted", "Extended"].includes(val.paymentStatus) ? val.payLaterPlan[0].productName : "Pay later";
      } else if (val.requestType == "recurring") {
        return "Recurring";
      }
    },
  },
  mounted() {
    this.getPaymentRequestList(this.partnerId, 1, 5);
  },

  watch: {
    type(_newValue, _oldValue) {
      this.getPaymentRequestList(this.partnerId, 1, 5);
    },
  },
};
</script>