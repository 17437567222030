<template>
  <vs-col vs-w="12">
    <vs-card class="transaction">
      <div slot="header" class="flex justify-between items-center">
        <h3 class="font-normal md:text-lg text-sm">Payment request templates</h3>
      </div>
      <vs-row class="flex-wrap items-end">
        <div class="paycard-wrap flex flex-wrap" v-if="paymentRequestTemplates.length > 0">
        <div v-for="(requestTemplate, key) in paymentRequestTemplates" :key="key" class=" payment-page-cards cursor-pointer" vs-type="flex" vs-justify="center" vs-align="center" v-on:click="selectRequestTemplate(requestTemplate, key)">
          <vs-card class="reqTemplateCard hover:cusror-pointer" :class="{accessTypeCardActive: paymentRequestTemplates[key].useTheme,}">
            <p class="font-normal text-center">{{ requestTemplate.templateName }}</p>
          </vs-card>
        </div>
        </div>
        <div class="paycard-wrap flex flex-wrap" v-else>
          <p>Contact your administrator to arrange access to send payment requests</p>
        </div>
        <div class="flex items-end h-full">
          <vs-button class="w-full" v-round @click="$router.push({ name: 'payment-request-create', params: { reqTemId: selectedTemplate._id },})" :disabled="!selectedTemplate">
            <span class="h-5 w-5 inline-flex"><img :src=sendIcon alt="sendicon"/></span> <span class="ml-2">Send payment request</span>
          </vs-button>
        </div>
      </vs-row>
    </vs-card>
  </vs-col>
</template>

<script>
import { mapActions } from "vuex";
const sendIcon=require("@/assets/images/pages/send.png")
export default {
  data() {
    return {
      paymentRequestTemplates: [],
      selectedTemplate: "",
      sendIcon:sendIcon
    };
  },
  methods: {
    ...mapActions("paymentRequestTemplate", [
      "fetchRequestTemplateByMerchantIdandGroup",
    ]),

    selectRequestTemplate(rt, index) {
      this.paymentRequestTemplates.map((e) => {
        e.useTheme = false;
      });
      this.paymentRequestTemplates[index].useTheme = true;
      this.selectedTemplate = this.paymentRequestTemplates[index];
    },

    async getPaymentRequestTemplates(partnerId) {
      await this.fetchRequestTemplateByMerchantIdandGroup({
        merchantId: partnerId,
        type: "template",
      })
        .then((result) => {
          this.paymentRequestTemplates = result.data.data;
          this.paymentRequestTemplates.map((element) => {
            element.useTheme = false;
          });
          this.paymentRequestTemplates = this.paymentRequestTemplates.filter(template => (template.templateType == 'simple' || template.templateType == 'advance'));
          if (this.paymentRequestTemplates.length > 0) {
            this.selectRequestTemplate(null, 0)
          }
        })
        .catch((err) => {});
    },
  },
  created() {
    this.getPaymentRequestTemplates(this.partnerId);
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return this.user.userType === "admin" ? this.user._id : this.user.partnerId;
    },
  },
};
</script>
