<template>
  <div id="dashboardPage">
    <vs-row vs-w="12" class="p-0 row-one">
      <PaymentPageWidgets v-if="showData"></PaymentPageWidgets>
    </vs-row>

    <vs-row vs-w="12" class="p-0 row-one mb-4">
      <PaymentRequestWidgets v-if="showData"></PaymentRequestWidgets>
    </vs-row>

    <vs-row vs-w="12" class="p-0 row-three justify-between" v-if="showData">
      <CardTypeWidgets :dateRange="dateRange"></CardTypeWidgets>
      <SuccessRateWidgets :dateRange="dateRange"></SuccessRateWidgets>
      <RequestByStatusWidgets :dateRange="dateRange"></RequestByStatusWidgets>
    </vs-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PaymentRequestWidgets from "./widgets/PaymentRequestWidgets.vue";
import PaymentPageWidgets from "./widgets/PaymentPageWidgets.vue";
import CardTypeWidgets from "./widgets/CardTypeWidgets.vue";
import SuccessRateWidgets from "./widgets/SuccessRateWidgets.vue";
import RequestByStatusWidgets from "./widgets/RequestByStatusWidgets.vue";
import moment from "moment";

export default {
  components: {
    PaymentRequestWidgets,
    PaymentPageWidgets,
    CardTypeWidgets,
    SuccessRateWidgets,
    RequestByStatusWidgets,
  },
  data() {
    return {
      dateRange: [],
      showData: false
    }
  },
  methods: {
    ...mapActions("admin", ["checkLogin"]),

    getPreviousMonthDateRange() {
      let firstDayOfdate = moment().subtract(30, 'days').format("DD/MM/YYYY");   // get 30 days before from today date.
      let lastDayOfDate = moment().format("DD/MM/YYYY");
      this.dateRange = [firstDayOfdate, lastDayOfDate];
    },
  },
  beforeMount() {
    this.getPreviousMonthDateRange();
    this.checkLogin().then(result => {
      this.showData = true;
    });
  }
};
</script>