<template>
  <div class="card-type-wid">
    <vs-card class="card-type graph-card">
      <div slot="header">
        <div class="flex cardtitle justify-between items-center">
          <h3 class="w-min font-normal md:text-lg text-sm">Card type</h3>
          <date-picker
            valueType="format"
            v-model="dateRangeCard"
            @change="selectDate()"
            lang="en"
            ref="widgetPopup"
            type="date"
            range
            placeholder=""
            class="w-auto widget-header-date-input xs-date"
            format="DD/MM/YYYY" range-separator="-"
          ></date-picker>
        </div>
      </div>
      <div>
        <div class="mt-5">
          <div class="chart-wrap">
            <div id="chart" v-if="showChart">
              <apexchart type="donut" width="320" :options="chartOptions" :series="series"></apexchart>
              <div class="cardtype-tog flex items-center lg:ml-4 md:ml-4 ml-8">
                <h4 :class="{ bold: active }">Value</h4>
                <vs-switch @change="changeValue()" vs-value="amount" class="mt-1 mx-1" v-model="switch2" @click="active = !active" :aria-pressed="active ? 'true' : 'false'"/>
                <h4 >Qty</h4> 
              </div>
            </div>
            <div v-else id="chart">
              <p align='center'>No data available</p> 
            </div>
          </div>
        </div>
      </div>
    </vs-card>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { mapActions } from "vuex";
import apexchart from "vue-apexcharts";
export default {
  components: {
    DatePicker,
    apexchart
  },
  props:['dateRange'],
  data() {
    return {
      switch2: false,
      showChart: false,
      active: true,
      dateRangeCard: [],
      sections: [],
      series: [0, 0, 0, 0, 0],
      chartOptions: {
        chart: {
          width: 380,
          type: "donut",
          animations: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        labels: [],
        colors: ["#00CFFF", "#01013F", "#219653", "#EB5757", "#F2C94C"],
        tooltip: {
          y: {
            formatter: function(labels) {
              return labels + "%";
            }
          }
        },
        stroke: {
          show: false,
          curve: "smooth",
          lineCap: "butt",
          colors: undefined,
          width: 0,
          dashArray: 1
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300
              },
              legend: {
                position: 'top'
              }
            }
          }
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 230
        }
      }
    };
  },
  methods: {
    ...mapActions("partnerDashboard", ["fetchCardDetails"]),
    async getCardDetail() {
      this.$vs.loading();
      const startDate = this.dateRangeCard[0].split("/").reverse().join("-");
      const endDate = this.dateRangeCard[1].split("/").reverse().join("-");
      const dateRange = [startDate, endDate];
      
      await this.fetchCardDetails(dateRange)
        .then(result => {
          let sum = 0;
          this.series = this.switch2 ? result.data.data.chartData.count : result.data.data.chartData.value;
          sum = this.series.reduce((a, b) => {
            return a + b;
          });
          this.showChart = (sum > 0);
          this.chartOptions.labels = result.data.data.cards;
          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Notification",
            text: err.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
    },

    selectDate() {
      this.getCardDetail();
      this.$refs.widgetPopup.closePopup();
      
    },

    changeValue(){
      this.getCardDetail();
    },
  },

  beforeMount() {
    this.dateRangeCard = this.dateRange;
  },
  mounted(){
    this.getCardDetail();
  }
};
</script>
