<template>
  <div class="success-rate-wid">
    <vs-card class=" card-type success-rate">
      <div slot="header" class="card-header mb-0">
        <div class="flex cardtitle justify-between  items-center">
          <h3 class="w-auto font-normal mb-0 md:text-lg text-sm whitespace-pre">Payment method</h3>
          <date-picker
            v-model="dateRangeSuccess"
            ref="datepicker"
            valueType="format"
            @change="selectDate()"
            lang="en"
            type="date"
            range
            placeholder=""
            class="w-auto widget-header-date-input xs-date"
            format="DD/MM/YYYY"
            range-separator="-"
            :default-value="new Date()"
          >
          </date-picker>
        </div>
      </div>
      <div class="card-body">
        <vs-row vs-w="12" v-if="successRate.payNow.total > 0 || successRate.recurring.total > 0 || successRate.payLater.total > 0">
          <vs-col class="success-rate" vs-offset="1" vs-type="flex" vs-justify="center" vs-align="center" vs-w="9">
            <vs-col vs-type="flex" vs-justify="space-between" class="success-card-font">
              <h3 class="text-default">Pay Now</h3>
              <h3 :class="successRate.payNow.color + ' font-semibold'">{{ successRate.payNow.total }}%</h3>
            </vs-col>
          </vs-col>

          <vs-col class="success-rate" vs-offset="1" vs-type="flex" vs-justify="center" vs-align="center" vs-w="9">
            <vs-col vs-type="flex" vs-justify="space-between" class="success-card-font">
              <h3 class="text-default">Recurring</h3>
              <h3 :class="successRate.recurring.color + ' font-semibold'">{{ successRate.recurring.total }}%</h3>
            </vs-col>
          </vs-col>

          <vs-col class="success-rate" vs-offset="1" vs-type="flex" vs-justify="center" vs-align="center" vs-w="9">
            <vs-col vs-type="flex" vs-justify="space-between" class="success-card-font">
              <h3 class="text-default">Pay Later</h3>
              <h3 :class="successRate.payLater.color + ' font-semibold'">{{ successRate.payLater.total }}%</h3>
            </vs-col>
          </vs-col>
        </vs-row>
        <div v-else>
          <p align='center'>No data available</p>
        </div>
      </div>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
export default {
  components: {
    DatePicker,
  },
  props:['dateRange'],
  data() {
    return {
      dateRangeSuccess: [],
      successRate: {
        payNow: {
          color: "text-danger",
          total: 0
        },
        payLater: {
          color: "text-danger",
          total: 0
        },
        recurring: {
          color: "text-danger",
          total: 0
        },
      },
    };
  },
  methods: {
    ...mapActions("paymentRequest", ["fetchSuccessRateByDate"]),
    async getSuccessDetail() {
      this.$vs.loading();
      const startDate = this.dateRangeSuccess[0].split("/").reverse().join("-");
      const endDate = this.dateRangeSuccess[1].split("/").reverse().join("-");
      const dateRange = [startDate, endDate];

      await this.fetchSuccessRateByDate(dateRange)
        .then((result) => {
          let rate = result.data.data;
          this.successRate.payNow = rate.payNow;
          this.successRate.recurring = rate.recurring;
          this.successRate.payLater = rate.payLater;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Notification",
            text: err.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },

    selectDate() {
      this.getSuccessDetail();
    },
  },
  beforeMount() {
    this.dateRangeSuccess = this.dateRange;
  },
  mounted() {
    this.getSuccessDetail();
  },
};
</script>
