<template>
  <div class="request-by-status-wid">
    <vs-card class="card-type req-by-status">
      <div slot="header">
        <div class="flex cardtitle justify-between items-center">
          <h3 class="w-min font-normal md:text-lg text-sm">Requests by status</h3>
          <date-picker
            v-model="dateRangeRequest"
            @change="changeDate()"
            valueType="format"
            lang="en"
            ref="widgetPopup"
            type="date"
            range
            placeholder=""
            class="w-auto widget-header-date-input xs-date"
            format="DD/MM/YYYY"
            range-separator="-"
          ></date-picker>
        </div>
      </div>
      <div class="card-body">
        <vs-col vs-type="flex" vs-justify="center">
          <h1 class="text-primary font-semibold mid-blue large-font"><template v-if="switch2 == false"> $ </template>{{ responseValue }}</h1>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" class="mt-3 mb-10">
          <p> <template v-if="!switch2"> of </template> requests {{ select1.toLowerCase() }}</p>
        </vs-col>
        <vs-col class="flex lg:justify-center md:justify-center sm:justify-between justify-between mb-2">
          <div class="status-select">
            <h4 class="mr-2 mt-1 mb-0 font-bold">Status</h4>
            <vs-select @change="changeStatus()" class="status-option" v-model="select1" size="small">
              <vs-select-item :key="index" :value="item.status" :text="item.status" v-for="(item, index) in options1" />
            </vs-select>
          </div>

          <div class="quantity-select">
            <h4 :class="{ bold: active }">Value</h4>
            <vs-switch @change="changeValue()" vs-value="amount" class="mt-1 mx-1" v-model="switch2" @click="active = !active" :aria-pressed="active ? 'true' : 'false'" />
            <h4>Qty</h4>
          </div>
        </vs-col>
      </div>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
export default {
  components: {
    DatePicker,
  },
  props:['dateRange'],
  data() {
    return {
      switch2: false,
      active: true,
      select1: "Active",
      options1: [],
      dateRangeRequest: [],
      partnerId:
        JSON.parse(localStorage.getItem("user")).userType == "admin"
          ? JSON.parse(localStorage.getItem("user"))._id
          : JSON.parse(localStorage.getItem("user")).partnerId,
      responseValue: 0,
    };
  },
  methods: {
    moveElement(array, from, to) {
      const copy = [...array];
      const valueToMove = copy.splice(from, 1)[0];
      copy.splice(to, 0, valueToMove);
      return copy;
    },
    getNotificationsSettings() {
      this.$store
        .dispatch(
          "merchantSettings/fetchMerchantNotificationSettingsByPartner",
          this.partnerId
        )
        .then((result) => {
          this.options1 = result.data.data.prNotification;
          this.options1 = this.moveElement(this.options1, 0, 1);
          this.options1 = this.moveElement(this.options1, 3, 2);
          this.options1 = this.moveElement(this.options1, 4, 3);
          this.options1 = this.moveElement(this.options1, 6, 4);
        })
        .catch((err) => {});
    },

    async getValueByStatus() {
      this.$vs.loading();
      const startDate = this.dateRangeRequest[0].split("/").reverse().join("-");
      const endDate = this.dateRangeRequest[1].split("/").reverse().join("-");

      const params = {
        date: this.dateRangeRequest ? startDate + "," + endDate : "",
        status: this.select1,
        value: this.switch2,
      };
      if (params.status === "Complete") { 
        params.status = "Completed";
      }
      let paramsAdd = `?date=${params.date}&status=${params.status}&value=${params.value}`;

      this.$store
        .dispatch("paymentRequest/fetchAmountOrValueByStatusAndDate", paramsAdd)
        .then((result) => {
          this.responseValue = result.data.data;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Notification",
            text: err.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },

    changeValue() {
      this.getValueByStatus();

    },
    changeDate() {
      this.getValueByStatus();
      this.$refs.widgetPopup.closePopup();
    },

    changeStatus() {
      this.getValueByStatus();
    },
  },
  beforeMount() {
    this.dateRangeRequest = this.dateRange;
    this.getNotificationsSettings();
  },
  mounted(){
    this.getValueByStatus();
  }
};
</script>
